import { Login } from 'components/pages/Login/Login';
import { EntryPoint, NextPageWithEntryType } from 'utils/nextTypes';

const LoginPage: NextPageWithEntryType = function LoginPage() {
  return <Login />;
};

LoginPage.entryPointType = EntryPoint.AUTH;

export default LoginPage;
