import { useTranslation } from 'react-i18next';

import { dialogGradientDark } from 'components/theme/colors';
import {
  Box,
  Button,
  EnvelopeIcon,
  Flex,
  Heading,
  Link,
  oauthButtons,
  Text,
} from 'components/uikit';
import { CalliperLogoWideWhite } from 'components/uikit/icons/CalliperLogoWideWhite';
import { preSignUpFailed } from 'data/auth/amplify';
import { useIsMobile } from 'utils/hooks/screenBreakpoints';

interface LoginFormProps {
  onGoogleLogin: () => Promise<void>;
  onEmailSelect: () => void;
  isLoading: boolean;
  googleError: string | null;
  logInFormActive?: boolean;
}

export function AuthForm({
  onGoogleLogin,
  onEmailSelect,
  isLoading,
  googleError,
  logInFormActive = false,
}: LoginFormProps) {
  const { t } = useTranslation('auth');
  const isMobile = useIsMobile();

  return (
    <Flex w='100%' h='100%' flexDir='row'>
      <Flex
        w='100%'
        h='100%'
        background={dialogGradientDark}
        flexDir='column'
        boxShadow='4px 4px 6px -2px rgba(16, 24, 40, 0.04), 12px 12px 16px -4px rgba(16, 24, 40, 0.08)'
      >
        <CalliperLogoWideWhite
          height='32px'
          width='140px'
          alignSelf='center'
          my='32px'
        />
        <Box h='calc(100%-96px)' flex={1}>
          <Box
            display='flex'
            flexDirection='column'
            h='100%'
            width='100%'
            alignItems='center'
            justifyContent='center'
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              w={isMobile ? '90%' : '464px'}
              position='relative'
              borderRadius='8px'
              border='1px solid'
              borderColor='grey.border'
              boxShadow='0px 4px 8px -2px #1018281A'
              overflow='hidden'
            >
              <Box
                w='100%'
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
                padding={['24px', '48px 60px 25px 60px']}
                backgroundColor='grey.white'
                textAlign='center'
              >
                <Heading
                  as='h1'
                  fontSize='4xl'
                  color='grey.primaryText'
                  fontWeight='semibold'
                  textAlign='center'
                  width='100%'
                >
                  {t(
                    logInFormActive
                      ? 'welcome_heading_has_account'
                      : 'welcome_heading',
                  )}
                </Heading>
                <Text
                  as='p'
                  fontSize='md'
                  color='grey.tertiaryText'
                  mt='12px'
                  width='100%'
                >
                  {t(
                    logInFormActive
                      ? 'welcome_sub_heading_has_account'
                      : 'welcome_sub_heading',
                  )}
                </Text>

                <Box w='100%' mt='16px'>
                  <oauthButtons.Google
                    onClick={onGoogleLogin}
                    disabled={isLoading}
                    isLoading={isLoading}
                    loadingText={t('sign_in_with_google')}
                    width='100%'
                    height='48px'
                    fontSize='16px'
                    backgroundColor={'grey.white'}
                    borderColor={'grey.border'}
                    color={'grey.secondaryText'}
                    _hover={{
                      backgroundColor: 'grey.offWhite',
                    }}
                  >
                    {t(
                      logInFormActive
                        ? 'sign_in_with_google'
                        : 'sign_up_with_google',
                    )}
                  </oauthButtons.Google>
                </Box>

                {preSignUpFailed || googleError ? (
                  <Text mt='8px' alignSelf='center' color='error.button'>
                    {preSignUpFailed ? t('personal_email_error') : googleError}
                  </Text>
                ) : null}

                <Button
                  w='100%'
                  height='48px'
                  mt='16px'
                  variant='outline'
                  disabled={isLoading}
                  isLoading={isLoading}
                  onClick={onEmailSelect}
                  fontSize='16px'
                  backgroundColor={'grey.white'}
                  borderColor={'grey.border'}
                  color={'grey.secondaryText'}
                  _hover={{
                    backgroundColor: 'grey.offWhite',
                  }}
                  leftIcon={
                    <EnvelopeIcon boxSize='24px' color='grey.secondaryText' />
                  }
                >
                  {t(logInFormActive ? 'sign_in_email' : 'sign_up_email')}
                </Button>

                <Text
                  fontWeight='medium'
                  mt='16px'
                  color='grey.tertiaryText'
                  fontSize='xs'
                  alignSelf='center'
                >
                  {t(logInFormActive ? 'no_account' : 'existing_account')}{' '}
                  <Link href={logInFormActive ? '/sign-up' : '/login'}>
                    <Button
                      fontWeight='medium'
                      variant='link'
                      fontSize='xs'
                      color='secondary.button'
                    >
                      {t(
                        logInFormActive ? 'link_to_sign_up' : 'link_to_sign_in',
                      )}
                    </Button>
                  </Link>
                </Text>

                {logInFormActive ? null : (
                  <Text
                    fontSize='xs'
                    fontWeight='medium'
                    mt='16px'
                    color='grey.tertiaryText'
                  >
                    {t('terms_description')}{' '}
                    <Link href='https://www.calliper.app/terms'>
                      <Button
                        fontWeight='medium'
                        variant='link'
                        fontSize='xs'
                        color='midTextDark'
                        textDecoration='underline'
                      >
                        {t('terms_link')}
                      </Button>
                    </Link>{' '}
                    {t('terms_and')}{' '}
                    <Link href='https://www.calliper.app/privacy'>
                      <Button
                        fontWeight='medium'
                        variant='link'
                        fontSize='xs'
                        color='midTextDark'
                        textDecoration='underline'
                      >
                        {t('privacy_link')}
                      </Button>
                    </Link>
                    .
                  </Text>
                )}
              </Box>

              <Box
                w='100%'
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
                borderTop='1px solid'
                borderColor='grey.border'
                padding={['8px', '16px 8px']}
                backgroundColor='grey.offWhite'
                textAlign='center'
              >
                <Text
                  alignSelf='center'
                  color='grey.secondaryText'
                  fontSize='sm'
                  fontWeight='medium'
                >
                  {t('welcome_beta')}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box w='100%' p='32px'>
          <Text fontSize='sm'>{t('calliper_copyright_text')}</Text>
        </Box>
      </Flex>
    </Flex>
  );
}
