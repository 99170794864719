import { AuthForm } from 'components/LoginForm/AuthForm';
import { useGoogleLogin } from 'data/auth/hooks/useGoogleLogin';
import { useNavigate } from 'utils/navigation';
import paths from 'utils/paths';

export function Login() {
  const {
    signIn: googleSignIn,
    loading: googleLoading,
    error: googleError,
  } = useGoogleLogin();
  const navigate = useNavigate();

  return (
    <AuthForm
      onGoogleLogin={googleSignIn}
      onEmailSelect={() => {
        navigate(paths.login.email);
      }}
      googleError={googleError}
      isLoading={googleLoading}
      logInFormActive
    />
  );
}
