import { useCallback, useState } from 'react';

import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { getInitialRoute } from 'data/auth/hooks/initialRoute';

export const useGoogleLogin = (requestedNextPath: string | null = null) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const signIn = useCallback(async () => {
    setLoading(true);

    const nextPath = requestedNextPath ?? getInitialRoute();

    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: nextPath !== null ? JSON.stringify({ nextPath }) : undefined,
    }).catch(setError);

    setLoading(false);
  }, [requestedNextPath]);

  return { signIn, error, loading };
};
